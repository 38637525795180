import { Routes } from '@angular/router';
import { IsAuthenticatedGuard } from '@core/guards/is-authenticated.guard';
import { IsBrowserGuard } from '@core/guards/is-browser.guard';
import { IsNotAuthenticatedGuard } from '@core/guards/is-not-authenticated.guard';
import { IsStaffGuard } from '@core/guards/is-staff.guard';
import {
    FeatureFlagResolver,
    FeatureFlags,
} from '@core/services/feature-flag.service';
import { SeoResolver } from '@core/services/seo.service';

export const appRoutes: Routes = [
    {
        path: 'my-profile',
        resolve: { seo: SeoResolver },
        canActivate: [IsNotAuthenticatedGuard],
        loadComponent: () =>
            import('./pages/profile-page/profile-page.component').then(
                m => m.ProfilePageComponent
            ),
    },
    {
        path: 'sign-in',
        resolve: { seo: SeoResolver },
        canActivate: [IsNotAuthenticatedGuard],
        loadComponent: () =>
            import(
                './pages/user-pages/user-login-page/user-login-page.component'
            ).then(m => m.UserLoginPageComponent),
    },
    {
        path: 'logout',
        resolve: { seo: SeoResolver },
        canActivate: [IsAuthenticatedGuard],
        loadComponent: () =>
            import(
                './pages/user-pages/user-logout-page/user-logout-page.component'
            ).then(m => m.UserLogoutPageComponent),
    },

    {
        path: 'adventure-trips',
        children: [
            {
                path: 'discover',
                loadComponent: () =>
                    import(
                        './pages/trips-pages/trip-list-discover-page/trip-list-discover-page.component'
                    ).then(m => m.TripListDiscoverPageComponent),
            },
            {
                path: ':slug/checkout',
                resolve: { featureFlag: FeatureFlagResolver },
                data: {
                    featureFlags: [
                        {
                            featureFlag: FeatureFlags.SELF_BOOKING,
                            value: true,
                        },
                    ],
                },
                loadComponent: () =>
                    import(
                        './pages/trips-pages/trip-detail-page/trip-detail-page.component'
                    ).then(m => m.TripDetailPageComponent),
            },
            {
                path: ':slug/:itinerary',
                loadComponent: () =>
                    import(
                        './pages/trips-pages/trip-variation-detail-page/trip-variation-detail-page.component'
                    ).then(m => m.TripVariationDetailPageComponent),
            },
            {
                path: ':slug',
                resolve: { featureFlag: FeatureFlagResolver },
                data: {
                    featureFlags: [
                        {
                            featureFlag: FeatureFlags.SELF_BOOKING,
                            value: false,
                        },
                    ],
                },
                loadComponent: () =>
                    import(
                        './pages/trips-pages/trip-detail-page/trip-detail-page.component'
                    ).then(m => m.TripDetailPageComponent),
            },
            {
                path: '',
                loadComponent: () =>
                    import(
                        './pages/trips-pages/trip-list-page/trip-list-page.component'
                    ).then(m => m.TripListPageComponent),
            },
        ],
    },

    {
        path: 'trips',
        redirectTo: 'adventure-trips',
        pathMatch: 'prefix',
    },
    {
        path: 'not-authenticated',
        loadComponent: () =>
            import(
                './pages/not-authenticated-page/not-authenticated.component'
            ).then(m => m.NotAuthenticatedComponent),
    },
    {
        path: 'create-an-account',
        canActivate: [IsNotAuthenticatedGuard],
        loadComponent: () =>
            import(
                './pages/user-pages/user-register-page/user-register-page.component'
            ).then(m => m.UserRegisterPageComponent),
    },
    {
        path: 'forgot-password',
        canActivate: [IsNotAuthenticatedGuard],
        loadComponent: () =>
            import(
                './pages/user-pages/user-password-reset-page/user-password-reset-page.component'
            ).then(m => m.UserPasswordResetPageComponent),
    },
    {
        path: 'forgot-password/reset/:uid/:token',
        canActivate: [IsNotAuthenticatedGuard],
        loadComponent: () =>
            import(
                './pages/user-pages/user-new-password-page/user-new-password-page.component'
            ).then(m => m.UserNewPasswordPageComponent),
    },
    {
        path: 'forms/:slug',
        loadComponent: () =>
            import('./pages/forms-page/forms-page.component').then(
                m => m.FormsPageComponent
            ),
    },
    {
        path: 'contact',
        loadComponent: () =>
            import('./pages/contact-page/contact-page.component').then(
                m => m.ContactPageComponent
            ),
    },
    {
        path: 'about-us',
        loadComponent: () =>
            import('./pages/about-us-page/about-us-page.component').then(
                m => m.AboutUsPageComponent
            ),
    },
    {
        path: 'not-found',
        resolve: { seo: SeoResolver },
        loadComponent: () =>
            import('./pages/not-found-page/not-found-page.component').then(
                m => m.NotFoundPageComponent
            ),
    },
    {
        path: 'knowledge/destination/:destination',
        redirectTo: 'knowledge/category/:destination',
        pathMatch: 'full',
    },
    {
        path: 'knowledge/category/:destination',
        loadComponent: () =>
            import(
                './pages/knowledge-pages/knowledge-destination-detail-page/knowledge-destination-detail-page.component'
            ).then(m => m.KnowledgeDestinationDetailPageComponent),
    },
    {
        path: 'knowledge/:article',
        loadComponent: () =>
            import(
                './pages/knowledge-pages/knowledge-article-page/knowledge-article-page.component'
            ).then(m => m.KnowledgeArticlePageComponent),
    },
    {
        path: 'knowledge',
        loadComponent: () =>
            import(
                './pages/knowledge-pages/knowledge-destination-list-page/knowledge-destination-list-page.component'
            ).then(m => m.KnowledgeDestinationListPageComponent),
    },
    {
        path: 'checkout',
        canActivate: [IsBrowserGuard],
        children: [
            {
                path: 'payment-success/:payment_id',
                canActivate: [IsAuthenticatedGuard],
                loadComponent: () =>
                    import(
                        './pages/payment-success-page/payment-success-page.component'
                    ).then(m => m.PaymentSuccessPageComponent),
            },
            {
                path: ':departure_id/:reservation_id/:payment_id/payment-choice',
                canActivate: [IsAuthenticatedGuard],
                loadComponent: () =>
                    import(
                        './pages/payment-choice-page/payment-choice-page.component'
                    ).then(m => m.PaymentChoicePageComponent),
            },
            {
                path: ':departure_id/:reservation_id/:payment_id/payment-card',
                canActivate: [IsAuthenticatedGuard],
                loadComponent: () =>
                    import(
                        './pages/payment-card-page/payment-card-page.component'
                    ).then(m => m.PaymentCardPageComponent),
            },
            {
                path: ':departure_id/:reservation_id/billing',
                loadComponent: () =>
                    import('./pages/billing-page/billing-page.component').then(
                        m => m.BillingPageComponent
                    ),
            },
            {
                path: ':departure_id',
                loadComponent: () =>
                    import(
                        './pages/checkout-page/checkout-page.component'
                    ).then(m => m.CheckoutPageComponent),
            },
        ],
    },
    {
        path: '',
        loadComponent: () =>
            import('./pages/home-page/home-page.component').then(
                m => m.HomePageComponent
            ),
    },
    {
        path: 'office',
        canActivate: [IsAuthenticatedGuard, IsStaffGuard],
        loadComponent: () =>
            import('./pages/office-pages/office-pages.component').then(
                m => m.OfficePagesComponent
            ),
        loadChildren: () =>
            import('./pages/office-pages/office.routes').then(
                m => m.officeRoutes
            ),
    },
    {
        path: 'expert-chat',
        resolve: { seo: SeoResolver },
        children: [
            {
                path: ':slug',
                loadComponent: () =>
                    import('./pages/landbot-page/landbot-page.component').then(
                        m => m.LandbotPageComponent
                    ),
            },
            {
                path: '**',
                loadComponent: () =>
                    import(
                        './pages/not-found-page/not-found-page.component'
                    ).then(m => m.NotFoundPageComponent),
            },
        ],
    },
    {
        path: 'dashboard',
        canActivate: [IsAuthenticatedGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './pages/dashboard-page/dashboard-page.component'
                    ).then(m => m.DashboardPageComponent),
            },
            {
                path: 'settings',
                canActivate: [IsAuthenticatedGuard],
                loadComponent: () =>
                    import(
                        './pages/user-pages/user-settings-page/user-settings-page.component'
                    ).then(m => m.UserSettingsPageComponent),
            },
        ],
    },
    {
        path: 'invitation',
        resolve: { seo: SeoResolver },
        canActivate: [IsNotAuthenticatedGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        './pages/invitation-page/invitation-page.component'
                    ).then(m => m.InvitationPageComponent),
            },
            {
                path: ':referralCode',
                loadComponent: () =>
                    import(
                        './pages/invitation-page/invitation-page.component'
                    ).then(m => m.InvitationPageComponent),
            },
        ],
    },
    {
        path: 'getting-started',
        canActivate: [IsAuthenticatedGuard],
        children: [
            {
                path: '',
                redirectTo: 'what-does-travel-mean-to-you',
                pathMatch: 'full',
            },
            {
                path: 'picture',
                loadComponent: () =>
                    import(
                        './pages/getting-started-pages/getting-started-picture/getting-started-picture.component'
                    ).then(m => m.GettingStartedPictureComponent),
            },
            {
                path: 'where-are-you-from',
                loadComponent: () =>
                    import(
                        './pages/getting-started-pages/getting-started-where-are-you-from/getting-started-where-are-you-from.component'
                    ).then(m => m.GettingStartedWhereAreYouFromComponent),
            },
            {
                path: 'all-done',
                loadComponent: () =>
                    import(
                        './pages/getting-started-pages/getting-started-all-done/getting-started-all-done.component'
                    ).then(m => m.GettingStartedAllDoneComponent),
            },
            {
                path: 'what-does-travel-mean-to-you',
                loadComponent: () =>
                    import(
                        './pages/getting-started-pages/getting-started-what-does-travel-mean-to-you/getting-started-what-does-travel-mean-to-you.component'
                    ).then(
                        m => m.GettingStartedWhatDoesTravelMeanToYouComponent
                    ),
            },
        ],
    },
    {
        path: ':slug',
        loadComponent: () =>
            import('./pages/generic-page/generic-page.component').then(
                m => m.GenericPageComponent
            ),
    },
    {
        path: '**',
        resolve: { seo: SeoResolver },
        loadComponent: () =>
            import('./pages/not-found-page/not-found-page.component').then(
                m => m.NotFoundPageComponent
            ),
    },
];
