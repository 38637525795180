import {
    HTTP_INTERCEPTORS,
    provideHttpClient,
    withInterceptorsFromDi,
} from '@angular/common/http';
import {
    APP_ID,
    ApplicationConfig,
    ErrorHandler,
    Injectable,
    importProvidersFrom,
    provideZoneChangeDetection,
} from '@angular/core';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import {
    HAMMER_GESTURE_CONFIG,
    HammerGestureConfig,
    HammerModule,
    provideClientHydration,
    withNoHttpTransferCache,
} from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig,
    withViewTransitions,
} from '@angular/router';
import { GlobalErrorHandler } from '@core/error-handlers/global.error-handler';
import { TokenInterceptor } from '@core/interceptors/auth.interceptor';
import {
    LOCALSTORAGE,
    LocalStorageService,
} from '@core/services/local-storage.service';
import { WINDOW, WindowService } from '@core/services/window.service';
import { QuicklinkStrategy, quicklinkProviders } from 'ngx-quicklink';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { appRoutes } from './app.routes';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
    overrides = <any>{
        pinch: { enable: false },
        rotate: { enable: false },
        swipe: {
            direction: 6,
        },
    };
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(HammerModule),
        { provide: APP_ID, useValue: 'angular-universal' },
        {
            provide: WINDOW,
            useFactory: (windowService: WindowService) =>
                windowService.nativeWindow,
            deps: [WindowService],
        },
        {
            provide: LOCALSTORAGE,
            useFactory: (localStorageService: LocalStorageService) =>
                localStorageService.localStorage,
            deps: [LocalStorageService],
        },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        provideClientHydration(withNoHttpTransferCache()),
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi()),
        provideShareButtonsOptions(shareIcons()),
        quicklinkProviders,
        provideZoneChangeDetection({ eventCoalescing: true }),
        provideRouter(
            appRoutes,
            withPreloading(QuicklinkStrategy),
            withInMemoryScrolling({
                scrollPositionRestoration: 'enabled',
                anchorScrolling: 'enabled',
            }),
            withRouterConfig({
                paramsInheritanceStrategy: 'always',
            }),
            withViewTransitions()
        ),
    ],
};
